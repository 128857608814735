<template>
  <pdfviewer :pdfurl="pdfurl" />
</template>
<script>
import urlencode from "urlencode";
import {getTJReportfUrl} from '@/api/report'; 
import pdfviewer from '@/components/PdfView'
export default {
  name: 'TJReport',
  components: {
     pdfviewer
  }
  ,data(){
    return{
        pdfurl:"err.pdf",
    }
  },mounted(){
      getTJReportfUrl().then((response) => {
        this.pdfurl=urlencode(response.data);
      });
  }
}
</script>

<style scoped>

</style>
