import { render, staticRenderFns } from "./tjreport.vue?vue&type=template&id=219b46f8&scoped=true&"
import script from "./tjreport.vue?vue&type=script&lang=js&"
export * from "./tjreport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219b46f8",
  null
  
)

export default component.exports